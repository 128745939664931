<template>
  <v-container>
    <v-card elevation="0" class="pt-5">
      <div class="d-flex py-2 px-5" v-if="returnAbility('role:store')">
        <v-btn rounded depressed color="green" class="white--text ml-2" @click="$router.push('/createRole')">
          <v-icon>mdi-plus</v-icon>
          {{ $t('add role') }}
        </v-btn>
      </div>
      <app-base-table :tableOptions="tableOptions" @re-fetch-data="fetchData"
        @re-fetch-paginated-data="fetchData($event)" :enableDelete="false">
        <template slot="editFeature" slot-scope="{ row }">
          <v-icon color="info" @click="$router.push(`/editRole/${row.id}`)"
            v-if="returnAbility('role:update')">mdi-pencil</v-icon>
        </template>
      </app-base-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      tableOptions: "roles/getTableOptions"
    }),
  },
  methods: {
    ...mapActions({
      fetchRoles: "roles/fetchRoles",
    }),
    fetchData(page) {
      this.fetchRoles({
        page,
      });
    },
  },
  created() {
    this.fetchData(1);
  },
};
</script>

<style>

</style>